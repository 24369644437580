import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import Activity from './components/Activity';

const CV = () => {
  const { t } = useTranslation();

  const activities = t('activities', { returnObjects: true });

  console.log(activities);

  const activitiesOrder = activities.sort(function(a, b){
    return new Date(b.start) - new Date(a.start);
  });

  const [activitiesList, setList] = useState(activitiesOrder);

  function filterFulltime(activity) {
    return activity.fulltime === 1 && activity.aType === "job";
  }

  function filterStudies(activity) {
    return activity.fulltime === 1 && activity.aType === "studie";
  }

  let activitiesFulltime = activitiesOrder.filter(filterFulltime);
  let activitiesStudie = activitiesOrder.filter(filterStudies);

  function changeFilter(e) {
    switch(e.target.value) {
      case "fulltime":
        setList(activitiesFulltime);
        break;
      case "all":
        setList(activitiesOrder);
        break;
      case "studie":
        setList(activitiesStudie);
        break;
      default:
        console.log("Default switch");
    }
  }

  console.log(activitiesOrder);

  return (
    <div className='body'>

      <div className='content'>

        <h1> {t('headline.cv')} </h1>

        <div className="cvinfo">
    
          <div className="filter">
            <button onClick={changeFilter} value='all'>{t('activity.all')}</button>
            <button onClick={changeFilter} value='fulltime'>{t('activity.fulltime')}</button>
            <button onClick={changeFilter} value='studie'>{t('activity.studie')}</button>
          </div>

          {activitiesList.map(item => (
                <Activity
                  id={item.id}
                  title={item.title}
                  name={item.name}
                  start={item.start}
                  end={item.end}
                  info={item.info}
                  tools={item.tools}
                  to={t('activity.to')}
                  />
                ))}
        </div>

        <div className="clear" />
      
      </div>
          
    </div>);
}

export default CV;
