import { React, Component } from 'react';

class PrintHeader extends Component {
  
  render() {

    return (
      <p>Data & Cloud Engineering</p>
    )
  }
}

export default PrintHeader;