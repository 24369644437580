import React from 'react';
import { useTranslation } from 'react-i18next';

const Projects = () => {
    const { t } = useTranslation();
    const projects = t('projects', { returnObjects: true });

    return (
        <div className='body'>

            <div className='content'>

                <h1>{t('headline.projects')}</h1>

                {projects.map( proj => (
                    <div className="project" key={proj.id}>
                        <div className="title">{proj.title}</div>

                        <div className='periode'>
                            [ {proj.periode} ]   
                        </div>

                        <div className="clear" />

                        <div className='description'>
                            {proj.description}
                        </div>
                        
                        <div className='tools'>
                            <span className="label">Tools</span> : {proj.tools.join(", ")}
                        </div>

                        <div className='role'>
                            <span className="label"> Role </span> : {proj.role}
                        </div>

                        {proj.result !== 0 && (
                        <>
                            <div className='result'>
                                <span className="label">Result</span> :  {proj.result}
                            </div>
                        </>
                        )}

                    </div>
                ))}

            </div>

        </div>
    )
}

export default Projects;
