import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import images from './images/tsl_1000.jpg';
class Home extends Component {
  
  render() {
    const { t } = this.props;

    const profilText = t('profilText', { returnObjects: true });
    const competance = t('competance', { returnObjects: true });
    const corecompetance = t('corecompetance', { returnObjects: true });

    return (
      <div className='body'>

        <div className='content'> 

          <h1>
            {t('home.profil')}
          </h1>

          <p className="headlines">
          Data & Cloud Engineering | OO & Web Development | Solution Architecture
| System Integration
          </p>


          <h2>
            {t('home.summary')}
          </h2>

          <div className="profilarea">
            <div className="profil">
              {profilText.map( item => (
                  <span>{item}</span>
                ))}
            </div>

            <div className="images">
              {/* Insert Images  */}
              <img src={images} alt="images"/>
            </div>
          </div>

          <div className="clear" />

          <div className="profilDescription">

            <h2>{t('home.competenceList')}</h2>

            <div className="competance">
              <ul>
                {competance.map(item => (
                  <li> {item}</li>
                ))}
              </ul>
            </div>

            <div className="corecompetance">
              <p>{t('home.corecompetence')}</p>
              <ul>
                {corecompetance.map(item => (
                  <li> {item}</li>
                ))}
              </ul>

            </div>      

          </div>

          <div className="clear" />

        </div>

      </div>
    );
  }
}

export default withTranslation()(Home);
