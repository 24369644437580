import React from 'react';
import { useTranslation } from 'react-i18next';

const PersonalInfo = () => {
  const { t } = useTranslation();

  function calculateAge( birthday ) {
    var now = new Date();
    var diff = now.getTime() - birthday.getTime();
    var age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    return age;
  }

  const age  = calculateAge( new Date( 1969, 3, 21) );

  const health = t('personalInfo.health', { returnObjects: true });

  const languageSkills = t('personalInfo.languageskills', { returnObjects: true });

  const spareTime = t('personalInfo.spareTime', { returnObjects: true });

  return (
    <div className='body'>
      
      <div className='content'>

        <h1>{t('headline.personal')}</h1>

        <div className="personalInfo">

          <div className="person">
            <div className='label'>{t('personalInfo.age')} :</div>
            <div className='value'>{ age }</div>
          </div>

          <div className="person">
            <div className='label'>{t('personalInfo.coredata.birthplace.title')}:</div>
            <div className='value'>{t('personalInfo.coredata.birthplace.value')}</div>
          </div>

          <div className="person">
            <div className='label'>{t('personalInfo.coredata.nationality.title')}:</div>
            <div className='value'>{t('personalInfo.coredata.nationality.value')}</div>
          </div>

          <div className="person">
            <div className='label'>{t('personalInfo.coredata.status.title')}:</div>
            <div className='value'>{t('personalInfo.coredata.status.value')}</div>
          </div>

        </div>

        <div className="clear" />

        <div className="personalInfo">
          <h2> {t('personalInfo.headers.language')} </h2>
          <div className="person">
          {languageSkills.map(item => (
                  <span className="languageSkills">
                    <span className='label'> {item.name} : </span>
                    <span className='value'> {item.level} </span>
                    <div className="clear" />
                  </span>
            ))}
          </div>
        </div>

        <div className="clear" />

        <div className="personalInfo">
          <h2> {t('personalInfo.headers.health')} </h2>
          <div className="person">
            {health.map(item => (
                      <p> {item}</p>
              ))}
          </div>
        </div>

        <div className="clear" />

        <div className="personalInfo">
          <h2> {t('personalInfo.headers.sparetime')} </h2>
          <div className="person">
          {spareTime.map(item => (
                    <p> {item}</p>
                    ))}
          </div>
        </div>

        <div className="clear" />
      
      </div>

    </div>
  );
}

export default PersonalInfo;
