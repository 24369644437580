function Course(props) {
    return (
        <div key={props.id}>
            <div > <b>{props.name}</b> [ {props.company} ] 
            {props.certificate !== 0 && (
                <>
                    <span className="icon icon-trophy" title='With Certificat'></span>
                </>
            )}
            </div>
            {props.start !== props.end && (
                <>
                    <div>
                        {props.start} to {props.end}
                    </div>
                </>
            )}
            {props.start === props.end && (
                <>
                    <div> {props.start} ( {props.duration} )</div>
                </>
            )}
            {props.description !== 0 && (
                <>
                    <div>
                        {props.description}
                    </div>
                </>
            )}
        </div>

    )
}

export default Course