function Footer(props) {
  return(
    <div className='footer'>

      <div className='section'>
  
        <div className='footerColumn'>

          <div>
            <span className="icon icon-home2"></span>
             {props.address}
          </div>
    
          <div>
            <span className="icon icon-phone2"></span>
            <a href="tel:+4526181586"> <font className="tlf_prefix">+45</font> 26 18 15 86 </a>
          </div>
    
          <div>
            <span className="icon icon-at-sign"></span>
            <a href="mailto:thomas.stage@me.com"> thomas.stage (at) me.com </a>
          </div>

        </div>

        <div className='footerColumn'>

          <div>
            <span className="icon icon-linkedin2"></span>
            <a href="https://dk.linkedin.com/in/stagelarsen/da"> LinkedIn Profil </a>
          </div>

          <div>
            <span className="icon icon-file-download"></span>
             <a href='./files/cv_Thomas-stage-larsen_English.pdf'> {props.downloadPdf} </a>
          </div>

          <div>
            <span className="icon icon-vcard"></span> 
            <a href="./files/ThomasStageLarsen.vcf"> {props.vcard} </a>
          </div>
        </div>

        <div className="clear" />

      </div>
    </div>
  )
}

export default Footer