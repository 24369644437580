import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useState } from "react";
import logo from './images/de-logo.png';

// Navigation
const Nav = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  console.log("i18n");

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  console.log("After");

  return (
    <header className="nav">
      
      <div className="title">
        <div className="logo">
          <img src={logo} alt="dataengineer.dk"/>
        </div>
        <div className="titleText">
          <Link to="/"> Digital CV for Thomas S. Larsen </Link>
        </div>
      </div>

      <div className="titleMobil">
         <Link to="/"> CV : T. S. Larsen</Link>
      </div>

      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
 
      <button className="hamburger" onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }>
        <ul>
          <li>
            <NavLink to="/" exact activeStyle={{"opacity": 1}}> {t('menu.home')} 
            </NavLink>
          </li>
          <li>
            <NavLink to="/cv" activeStyle={{"opacity": 1}}> {t('menu.cv')} 
            </NavLink>
          </li>
          <li>
            <NavLink to="/Projects" activeStyle={{"opacity": 1}}> {t('menu.projects')} 
            </NavLink>
          </li>
          <li>
            <NavLink to="/Competence" activeStyle={{"opacity": 1}}> {t('menu.competence')} 
            </NavLink>
          </li>
          <li>
            <NavLink to="/personal" activeStyle={{"opacity": 1}}>{t('menu.personal')} 
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="languageShift">
        {i18n['language'] === "en" && (
          <>
            <span className='activeLanguage'>EN</span> /
            <button onClick={changeLanguage} value='dk' title="Switch to Danish">DK</button>
          </>
        )}
        {i18n['language'] === "dk" && (
          <>
            <span className='activeLanguage'>DK</span> /
            <button onClick={changeLanguage} value='en' title="Skift til Engelsk">EN</button> 
          </>
        )}
      </div>
    </header>
  );
}

export default Nav;
