
function Activity(props) {

    return(
        <div className="activity" key={props.id}>
            <div className="title">{props.title} </div>
            
            <div className="company"> {/*props.at*/}@ <i>{props.name}</i> </div>

            <div className="period"> [{props.start} {props.to} {props.end} ]</div>  
        
            <div className="clear" />

            {/* props.fulltime}:{props.aType */} 

            {props.info ? <div className="info">{props.info}</div> : null }

            {/* <div className="info"> <span className="icon icon-hammer-wrench"> </span> {props.info} ( {props.info} ) </div> */}

            {props.tools.length !== 0 && (
                <>
                    <div className="tools">Tools: {props.tools.join(", ")} </div>
                </>
            )}

            {/* props.tools <span className="icon icon-hammer-wrench"> </span> */}
        </div>
    );

}

export default Activity