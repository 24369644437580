import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from './components/Footer';
import PrintHeader from './components/PrintHeader';
import Nav from './Nav';
import Home from './Home';
import cv from './cv';
import Projects from './Projects';
import Competance from './Competance';
import Personal from './Personal';

function App() {
  const { t } = useTranslation();
  return (
    <div className='app'>
      <Nav />

      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/cv' component={cv}/>
        <Route path='/Projects' component={Projects}/>
        <Route path='/Competence' component={Competance}/>
        <Route path='/personal' component={Personal}/>
        <Route path='/header' component={PrintHeader}/>
      </Switch>
  
      <div className="clear" />

      <Footer 
        address={t('footer.address')}
        downloadPdf={t('footer.downloadPdf')}
        vcard={t('footer.vcard')}/>

    </div>
  );
}

export default App;
