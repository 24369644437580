import { useState, React } from 'react';
import { useTranslation } from 'react-i18next';
import Course from './components/Course'

const Competance = () => {
  const { t } = useTranslation();

  const competanceList = t('competanceList', { returnObjects: true });
  const courseList = t('course', { returnObjects: true });

  const courseOrder = courseList.sort(function(a, b){
    return new Date(b.start) - new Date(a.start);
  });

  const [ competance, setCompetance ] = useState(competanceList)

  competance.forEach(element => {
    console.log("Number")
  });

  function filterTools(competance) {
    return competance.cType === "metode";
  }

  function filterCoding(competance) {
    return competance.cType === "programmering";
  }

  function filterTop(competance) {
    return competance.character.length > 2;
  }

  const competanceListToolsPrint = competanceList.filter(filterTools)
  const competanceListCodingPrint = competanceList.filter(filterCoding)
  const competanceListTopPrint = competanceList.filter(filterTop)

  function changeFilter(e) {
    switch(e.target.value) {
      case "all":
        setCompetance(competanceList)
        break;
      case "tools":
        setCompetance(competanceListToolsPrint)
        break;
      case "coding":
        setCompetance(competanceListCodingPrint)
        break;
      case "ctop":
        setCompetance(competanceListTopPrint)
        break;  
      case "methodes":
        break;
      default:
        alert("default");
    }
  }

  return (
      <div className='body'>

        <div className='content'>

          <h1>{t('headline.competence')}</h1>

          <div className="competenceGrid">

            <div className='competanceList'>

              <h3>{t('competanceHeader.title')}</h3>

              <div className="filter">‍
                <button onClick={changeFilter} value='all'>{t('competanceHeader.all')}  </button>
                <button onClick={changeFilter} value='coding'>{t('competanceHeader.coding')}</button>
                <button onClick={changeFilter} value='tools'>{t('competanceHeader.tools')}</button>
                <button onClick={changeFilter} value='metoder'>{t('competanceHeader.methods')}</button>
                <button onClick={changeFilter} activeStyle={"background-color: red;"} value='ctop'>{t('competanceHeader.top')}</button>
              </div>

              <table className="competance">
                <th>{t('competanceHeader.table.name')}</th>
                <th>{t('competanceHeader.table.level')}</th>
                <th>{t('competanceHeader.table.cType')}</th>
                {competance.map( comp => (
                  <tr key={comp.id}>
                      <td>{comp.name}</td>
                      <td>{comp.character.map( i => (
                        <span className="icon icon-star-full2"></span>
                      ))}</td>
                      <td> {comp.cType} </td>
                  </tr>
                ))}
              </table>
            
            </div>

            <div className="competanceList">
              <h3 className="course">Course</h3>

              {courseOrder.map( course => (
                <Course
                  id={course.id}
                  name={course.name}
                  company={course.company}
                  description={course.description}
                  duration={course.duration}
                  start={course.start}
                  end={course.end}
                  certificate={course.certificate}
                />
              ))}
            
            </div>

          </div>
        </div>

      </div>
  )
}

export default Competance;
